import React from "react";
import Anna from "../../assets/images/Anna.jpg";
import Ashley from "../../assets/images/Ashley.jpg";
import Caleb from "../../assets/images/Caleb.jpg";
import Cierra from "../../assets/images/Cierra.jpg";
import Misty from "../../assets/images/Misty.jpg";

const teamMembers = [
  {
    name: "Ashley Hunsaker",
    position: "Business Admin Department Manager",
    ext: "Ext. 470",
    image: Ashley,
  },
  {
    name: "Cierra Clark",
    position: "Business Admin Systems Administrator",
    ext: "Ext. 419",
    image: Cierra,
  },
  {
    name: "Anna White",
    position: "Entity Specialist",
    ext: "Ext. 732",
    image: Anna,
  },
  {
    name: "Misty Smith",
    position: "Tax Prep Specialist",
    ext: "Ext. 731",
    image: Misty,
  },
  {
    name: "Caleb Parker",
    position: "Entity Specialist",
    ext: "Ext. 495",
    image: Caleb,
  },
];

const Team = () => {
  return (
    <div>
      <div>
        <div className="bg-stone-50 py-4 max-w-full h-screen mt-2">
          <div className="max-w-7xl mx-auto px-2 py-2 sm:px-4 lg:px-6">
            <h2
              className="text-[28px] font-semibold tracking-wide text-center text-gray-800"
              style={{ fontFamily: "Merriweather, serif" }}
            >
              Meet the team
            </h2>
            <div className="max-w-full mt-3 border-t-[1.5px] border-gray-400 mb-4 pt-1">
              <dl className="grid grid-cols-3 mt-5  gap-8 h-80 justify-between">
                {teamMembers.map((member, index) => (
                  <div
                    key={index}
                    className="flex flex-col justify-center w-full min-w-[150px] p-2 m-2 bg-white rounded-lg"
                  >
                    <img
                      src={member.image}
                      alt={member.name}
                      className="w-75 h-75 rounded-xl mb-3 mx-auto p-2"
                    />
                    <h4
                      className="font-medium text-center text-base md:text-2xl border-b border-gray-400 mb-2"
                      style={{ fontFamily: "Merriweather, serif" }}
                    >
                      {member.name}
                    </h4>
                    <p
                      className="text-center min-w-[250px] font-medium text-base mb-0"
                      style={{
                        padding: "0.25em .5em",
                        fontFamily: "Merriweather, serif",
                        fontSize: "17px",
                      }}
                    >
                      {member.position}
                      <br />
                      {member.ext}
                    </p>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
