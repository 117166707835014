import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const PublicRoutes = (props) => {
    const { currentUser } = useAuth();

    return currentUser ? <Navigate to="/" /> : <Outlet />
}

export default PublicRoutes;
