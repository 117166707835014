import axios from "axios";
import jwtDecode from 'jwt-decode';
import React, { useContext, useState, useEffect } from "react";


const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [loginError, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState();

    function checkToken() {
        const token = localStorage.getItem('token');
        if (token && !isTokenExpired(token)) {
            return setCurrentUser(jwtDecode(token).user);
        }
        logout();
    }

    function isTokenExpired(token) {
        if (!token) {
            return true;
        }
        const expirationDate = getTokenExpirationDate(token);
        return expirationDate < new Date();
    }

    function getTokenExpirationDate(encodedToken) {
        const token = jwtDecode(encodedToken);
        if (!token.exp) { return null; }

        const date = new Date(0);
        date.setUTCSeconds(token.exp);

        return date;
    }

    useEffect(() => {
        checkToken();
        setLoading(false);
    }, []);


    function login(email, password, rememberMe) {
        axios
            .post(`${process.env.REACT_APP_API_URL}/corp-credit/login`, {
                email,
                password,
                rememberMe
            })
            .then((response) => {
                setCurrentUser(response.data.user)
                localStorage.setItem('token', response.data.access_token);
            }).catch((error) => {
                setError(error.response.data);
            });
    }

    function logout() {
        window.localStorage.clear();
        return setCurrentUser();
    }




    const value = {
        loginError,
        currentUser,
        login,
        logout
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}