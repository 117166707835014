import { Fragment, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { HiOutlineUserCircle, HiChevronDown } from "react-icons/hi";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import { client } from "../../client";

import logo from "../../assets/images/easier-accounting.png";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const [posts, setPosts] = useState(null);
  const { logout, currentUser } = useAuth();
  const [showDropdown, setShowDropdown] = useState(false);
  const menuRef = useRef(null);
  const navigation = [
    { name: "Home", href: "/", current: false },
    {
      name: "Corp Credit",
      current: false,
      href: '/corp-credit',
      subMenu: posts,
    },
    { name: "Contact Us", href: "/contact-us", current: false },
    { name: "Team", href: "/team", current: false }
  ];

  useEffect(() => {
    client
      .fetch("*[_type == 'post']{title,slug,orderRank}|order(orderRank)")
      .then((data) => {
        setPosts(data);
      })
      .catch(console.error);
  }, []);

  return (
    <>
      <Disclosure
        as="nav"
        className="bg-white h-[72px] shadow-md shadow-[#215369] flex flex-wrap items-center justify-between"
      >
        {({ open }) => (
          <>
            <div className="mx-auto w-full px-6 ">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden" ref={menuRef}>
                  {/* Mobile menu button*/}
                  <button
                    className="inline-flex items-center justify-center rounded-md p-2 text-gray-800"
                    onClick={() => setShowDropdown(!showDropdown)}
                  >
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon
                        className="block text-gray-800 bg-white h-6 w-6"
                        aria-hidden="true"
                      />
                    ) : (
                      <Bars3Icon
                        className="block text-gray-800 bg-white h-6 w-6"
                        aria-hidden="true"
                      />
                    )}
                  </button>

                  <div
                    className={`fixed bg-[#215369] text-white border-r-2 cursor-pointer border-gray-500 top-0 left-0 w-50 h-screen z-50 ${showDropdown ? "block" : "hidden"
                      }`}
                  >
                    <div className="py-2">
                      {navigation.map((item) => (
                        <div key={item.name} className="relative">
                          <a
                            href={item.href}
                            className="block rounded-md px-4 py-2 text-[14.5px] font-medium text-white"
                            onClick={() => setShowDropdown(!showDropdown)}
                          >
                            {item.name}
                          </a>
                          {item.subMenu && (
                            <div className="py-1">
                              {item.subMenu.map((subItem) => (
                                <Link
                                  key={subItem.slug.current}
                                  to={`/corp-credit/${subItem.slug.current}`}
                                  className="block py-2 text-[14.5px] ml-10 text-white bg-[#215369]"
                                  onClick={() => setShowDropdown(false)}
                                >
                                  {subItem.title}
                                </Link>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex flex-1 mt-3 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center mb-2">
                    <img
                      className=" h-[56px] mb-2 mr-2 w-auto lg:block block sm:block"
                      src={logo}
                      alt="Easier Accounting Corp Credit Builder"
                    />
                  </div>
                  <div className=" sm:ml-6 sm:block">
                    <div className="hidden space-x-4 md:flex lg:flex">
                      {navigation.map((item) =>
                        item.subMenu && item.subMenu.length > 0 ? (
                          <Menu
                            key={item.name}
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <div>
                              <Menu.Button
                                className={classNames(
                                  "flex rounded-md py-2 px-2 text-gray-800 hover:text-blue-700 underline text-[14.5px] mt-2",
                                  {
                                    "bg-gray-700 text-white": showDropdown,
                                  }
                                )}
                                onClick={() => setShowDropdown(true)}
                              >
                                <span >{item.name}</span>
                                <HiChevronDown size={24} className="inline-block" />
                              </Menu.Button>
                            </div>
                            {showDropdown && (
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items style={{ top: "calc(100% + .5rem", right: "0" }} className="absolute right-0 z-10 mt-2 w-72 border-b-2 border-r-2 border-l-2 border-gray-400 origin-top-right bg-white divide-y divide-gray-100 rounded-sm ">
                                  <div className="py-1">
                                    {item.subMenu.map((subItem) => (
                                      <Menu.Item key={subItem.slug.current}>
                                        {({ active }) => (
                                          <Link

                                            to={`/corp-credit/${subItem.slug.current}`}
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-800",
                                              "block px-4 py-2 text-[14.5px]"
                                            )}
                                            style={{

                                              fontWeight: 400,
                                              lineHeight: '20px',
                                              color: active ? '#374151' : '#4B5563',
                                              backgroundColor: active ? '#F3F4F6' : 'transparent',
                                              textDecoration: 'none',
                                              borderBottomWidth: '2px',
                                              borderBottomStyle: 'solid',
                                              borderBottomColor: active ? '#374151' : 'transparent'
                                            }}
                                            onClick={() => setShowDropdown(false)}
                                          >
                                            {subItem.title}
                                          </Link>
                                        )}
                                      </Menu.Item>
                                    ))}
                                  </div>
                                </Menu.Items>
                              </Transition>
                            )}
                          </Menu>
                        ) : (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              item.current
                                ? "bg-gray-900 text-white"
                                : "text-gray-800",
                              "rounded-md px-1 pt-3 text-[14.5px] underline"
                            )}
                            aria-current={item.current ? "page" : undefined}
                            onClick={() => setShowDropdown(false)}
                          >
                            {item.name}
                          </Link>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="flex rounded-full text-gray-600 text-[14.5px] focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2">
                        <span className="sr-only">Open user menu</span>
                        {currentUser.photoURL ? (
                          currentUser.photoURL
                        ) : (
                          <HiOutlineUserCircle size={30} className="mt-1 mr-2 hover:text-sky-600" />
                        )}
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute border right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              key="settings"
                              to="settings"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Settings
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              onClick={logout}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Sign out
                            </Link>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>
    </>
  );
}
