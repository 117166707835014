import React, { useState } from "react";
import { HiOutlineOfficeBuilding, HiOutlinePhone } from "react-icons/hi";
import { BsEnvelope } from "react-icons/bs";
import axios from "axios";
import { notification } from "antd";

export default function Contact() {
  const [submitMessage, setSubmitMessage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [formMessage, setFormMessage] = useState("");
  const [error, setError] = useState("");
  const [token] = useState(localStorage.getItem("token"));

  function handleSubmit(e) {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API_URL}/corp-credit/contact-email`, {
        name,
        email,
        phoneNumber: phone,
        message: formMessage,
      }, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
      })
      .then((response) => {
        setSubmitMessage("Email sent successfully.");
        setName("");
        setEmail("");
        setPhone("");
        setFormMessage("");
        notification.success({
          message: "Email sent successfully",
          placement: "topRight",
          duration: 4,
        });
      })
      .catch((error) => {
        setSubmitMessage("Error sending email.");
        notification.error({
          message: "Error sending email",
          placement: "topRight",
          duration: 4,
        })
      });
  }

  return (
    <div>
      <div
        className="grid grid-cols-1 md:grid-cols-2 mt-12 max-w-screen-lg mx-auto"
        style={{ alignItems: "flex-start" }}
      >
        <div className=" col-span-1 p-1 flex flex-col justify-center md:mt-20 -m-5">
          <h1
            className="font-semibold text-gray-800 mb-10 -mt-4 text-start"
            style={{ fontFamily: "Merriweather, serif", fontSize: "32px" }}
          >
            Contact Us
          </h1>
          <div className=" grid grid-cols-1 text-center">
            <div className="flex mt-3 items-center md:rounded-md">
              <div
                style={{
                  backgroundColor: "#07A5E6",
                  width: "2rem",
                  height: "2rem",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  marginBottom: "2rem",
                  marginRight: "1rem",
                }}
              >
                <HiOutlineOfficeBuilding size={22} color="white" />
              </div>
              <span className="text-gray-800 font-medium mb-3">
                162 N 400 E, STE C301
                <br />
                ST. GEORGE, UT 84770
              </span>
            </div>
            <div className="flex items-center md:rounded-md">
              <div
                style={{
                  backgroundColor: "#07A5E6",
                  width: "2rem",
                  height: "2rem",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  marginBottom: "2rem",
                  marginRight: "1rem",
                }}
              >
                <HiOutlinePhone size={22} color="white" />
              </div>
              <span className="text-gray-700 font-medium mb-3">
                <a className="hover:text-blue-600" href="tel:8886200770">
                  +1 (888)620-0770
                </a>
              </span>
            </div>
            <div className="flex items-center  md:rounded-md">
              <div
                style={{
                  backgroundColor: "#07A5E6",
                  width: "2rem",
                  height: "2rem",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  marginBottom: "2rem",
                  marginRight: "1rem",
                }}
              >
                <BsEnvelope size={22} color="white" />
              </div>
              <span className="text-gray-700 font-medium mb-3">
                <a
                  className="hover:text-blue-600"
                  href="mailto:corpcredit@easieraccounting.com"
                >
                  corpcredit@easieraccounting.com
                </a>
              </span>
            </div>
          </div>
        </div>

        <div className=" -ml-8 md:mt-3 col-span-1 w-[490px] md:w-[620px]">
          <form
            action="#"
            method="POST"
            onSubmit={handleSubmit}
            className="px-4 py-2 sm:pb-32 lg:py-40 lg:px-8"
            style={{ borderLeft: "1px solid #ccc" }}
          >
            <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-xl p-6">
              <div className="grid grid-cols-1 gap-4">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="first-name"
                    className="block text-[14.5px] font-medium leading-6"
                  >
                    Name
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoComplete="given-name"
                      className="block w-full rounded-md border-solid border-1 border-gray-300 py-2 px-3.5 shadow-[#215369] shadow-sm ring-1 ring-inset ring-gray/100 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="email"
                    className="block text-[14.5px] font-medium leading-6"
                  >
                    Email
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="email"
                      className="block w-full rounded-md border-solid border-1 border-gray-300 py-2 px-3.5 shadow-[#215369] shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="phone-number"
                    className="block text-[14.5px] font-medium leading-6"
                  >
                    Phone number
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="tel"
                      name="phone-number"
                      id="phone-number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      autoComplete="tel"
                      className="block w-full rounded-md border-solid border-1 border-gray-300 py-2 px-3.5 shadow-[#215369] shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="message"
                    className="block text-[14.5px] font-medium leading-6"
                  >
                    Message
                  </label>
                  <div className="mt-2.5">
                    <textarea
                      name="message"
                      id="message"
                      rows={4}
                      className="block w-full rounded-md border-solid border-1 border-gray-300 py-2 px-3.5 shadow-[#215369] shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                      value={formMessage}
                      onChange={(e) => setFormMessage(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-10 flex justify-start">
                <button
                  type="submit"
                  className="rounded-md bg-[#215369] px-3.5 py-2.5 text-center text-white text-sm font-semibold shadow-sm hover:bg-[#07A5E6] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                >
                  Send message
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
