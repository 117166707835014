import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import group from "../../assets/images/group-pic.jpg";
import { FaMobileAlt } from "react-icons/fa";
import { BsMegaphone } from "react-icons/bs";
import { MdOutlineRateReview } from "react-icons/md";
import { client } from "../../client";

export default function Home() {
  const navigate = useNavigate();
  const [firstPost, setFirstPost] = useState("");

  useEffect(() => {
    client
      .fetch(
        `*[_type == "post"]{
                _id,
                title,
                slug,
                mainImage{
                    asset->{
                        _id,
                        url
                    }
                },
                body,
                "name": author->name,
                "authorImage": author->image
            } | order(title, asc)`
      )
      .then((data) => {
        setFirstPost(data[0]);
      })
      .catch(console.error);
  }, []);

  function handleGetStarted() {
    navigate(`/corp-credit/${firstPost.slug.current}`)
  }

  return (
    <div>
      <div className="px-6 md:px-40 py-24 md:p-28 mx-auto w-full bg-stone-50 mt-2">
        <div className="mx-auto grid grid-cols-1 md:grid-cols-2 items-start gap-y-8 lg:mx-0  lg:grid-cols-2">
          <div className="md:col-span-1 ml-4">
            <div className="ml-4 md:text-left">
              <h1
                className="uppercase"
                style={{ fontSize: "55px", fontWeight: "400", fontFamily: "Merriweather, serif" }}
              >
                Build Your <br />
                Business <br /> Credit the <br />
                Right Way
              </h1>
            </div>
            <div className="mt-6 md:mt-12" style={{ paddingRight: "20%" }}>
              <p
                className="text-base md:text-xl"
                style={{ padding: "0.5em 1em" }}
              >
                Welcome to the Corporate Credit Builder Program.
                <br /> We understand the importance of establishing and building
                corporate credit, and are committed to helping your business
                achieve its financial goals.
              </p>{" "}
              <p
                className="text-base md:text-xl"
                style={{ padding: "0.5em 1em" }}
              >
                Our program is designed to guide you through the process of
                building a strong credit profile and establishing credibility
                with lenders, suppliers, and vendors. With our proven system you
                can take your business to the next level with confidence. Thank
                you for choosing the Easier Accounting’s Corporate Credit
                Builder Program as your partner in success.
              </p>
            </div>
          </div>
          <div className="md:col-span-1 lg:col-span-1 flex justify-center">
            <div className="shadow-xl flex justify-center shadow-[#215369] p-2 md:p-1 rounded-lg">
              <img
                alt="group pic"
                src={group}
                className="rounded-lg w-full md:h-auto"
              />
            </div>

          </div>

        </div>

        <div className="mx-auto w-full mt-48 flex justify-between">

          <div className="mx-auto grid grid-cols-1 md:grid-cols-3 gap-y-8 gap-x-4 lg:mx-0  lg:grid-cols-1">
            <button onClick={handleGetStarted} className="bg-[#215369] hover:bg-[#07A5E6] text-white text-xl font-normal uppercase py-2 px-4 rounded-md w-[350px] h-14">
              Get Started
            </button>
          </div>
        </div>

        <div className="mx-auto w-full mt-40 flex justify-between">
          <div className="mx-auto grid grid-cols-1 md:grid-cols-3 items-start gap-y-8 gap-x-4 lg:mx-0  lg:grid-cols-3">
            <div className="flex flex-col justify-center items-center p-2 bg-white border rounded-md">
              <div
                style={{
                  backgroundColor: "#07A5E6",
                  width: "3rem",
                  height: "3rem",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  marginBottom: "2rem",
                }}
              >
                <FaMobileAlt size={26} color="white" />
              </div>
              <p
                className="italic font-serif text-base md:text-lg"
                style={{ padding: "0.5em 1em" }}
              >
                “So easy to use, as a brand new business
                <br /> owner Easier Accounting has helped me with
                <br /> all my accounting needs and tax planning.
                <br />
                Recommend to any business big or small give
                <br /> them a call!” - Ernesto N
              </p>
            </div>
            <div className="flex flex-col justify-center items-center p-2 bg-white border rounded-md">
              <div
                style={{
                  backgroundColor: "#07A5E6",
                  width: "3rem",
                  height: "3rem",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  marginBottom: "2rem",
                }}
              >
                <BsMegaphone size={26} color="white" />
              </div>
              <p
                className="italic font-serif text-base md:text-lg"
                style={{ padding: "0.5em 1em" }}
              >
                “I have had an amazing experience so far with
                <br /> Easier Accounting. I am a new business owner
                <br /> with no clue what I am doing when it comes to
                <br /> accounting. They hold your hand through every
                <br /> step and always have time to answer any
                <br /> questions (no matter how basic or
                <br />
                complicated)! I feel like they’ve taken so much
                <br /> off my plate that I no longer have to worry
                <br /> about.” – Jenny K
              </p>
            </div>
            <div className="flex flex-col justify-center items-center p-2 bg-white border rounded-md">
              <div
                style={{
                  backgroundColor: "#07A5E6",
                  width: "3rem",
                  height: "3rem",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  marginBottom: "2rem",
                }}
              >
                <MdOutlineRateReview size={26} color="white" />
              </div>
              <p
                className="italic font-serif text-base md:text-lg"
                style={{ padding: "0.5em 1em" }}
              >
                “Friendly helpful staff! Definitely recommend if
                <br /> you are growing a business. This company is
                <br /> both honest and educational!” – Sharon M
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
