import React, { useEffect, useState, useRef } from "react";
import { client } from "../../client";
import { useParams, useNavigate } from "react-router-dom";
import BlockContent from "@sanity/block-content-to-react";
// import imageUrlBuilder from "@sanity/image-url";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";

export default function Post() {

  let timer = useRef(null);
  const { slug } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "post"]{
                _id,
                title,
                slug,
                orderRank,
                mainImage{
                    asset->{
                        _id,
                        url
                    }
                },
                body,
                "name": author->name,
                "authorImage": author->image
            }|order(orderRank)`
      )
      .then((data) => {
        setPosts(data);
        setCurrentIndex(data.findIndex((p) => p.slug.current === slug));
        setLoading(false);
      })
      .catch(console.error);

    const handleBeforeUnload = () => {
      if (timer) {
        clearTimeout(timer);
      }

      const post = posts[currentIndex];
      if (post) {
        recordArticleViews(post._id, post.title);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [slug]);

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      const post = posts[currentIndex];
      if (post) {
        recordArticleViews(post._id, post.title);
      }
    }, 10000);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [currentIndex]);

  const navigateTo = (index) => {
    const post = posts[index];
    if (post) {
      navigate(`/corp-credit/${post.slug.current}`);
      setCurrentIndex(index);
    }
  };

  const handlePrevClick = () => {
    const index = currentIndex - 1;

    navigateTo(index);
  };

  const handleNextClick = () => {
    const index = currentIndex + 1;

    navigateTo(index);
  };

  const post = posts[currentIndex];

  const recordArticleViews = (postId, postTitle) => {
    const viewedAt = new Date().toISOString();
    axios
      .post(`${process.env.REACT_APP_API_URL}/corp-credit/article-views`, {
        corpCreditUserId: currentUser.id,
        postId,
        postTitle,
        viewedAt,
      })
      .then((response) => {
        if (!response.status === 200) {
          throw new Error("Network response error.");
        }
      })
      .catch((error) => {
        console.error("There was a problem recording the article view.", error);
      });
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <div className="container mt-12">
        <h2
          className="font-medium mb-4 tracking-tight text-gray-900 text-3xl"
          style={{ fontFamily: "Merriweather, serif" }}
        >
          {post.title}
        </h2>
        <BlockContent
          className="text-lg text-black tracking-wide mt-12"
          blocks={post.body}
          projectId={client.projectId}
          dataset={client.dataset}
          style={{
            h2: {
              fontFamily: "Merriweather, serif"
            }
          }}
        />
      </div>
      <div className="flex px-80 justify-between mt-24 mb-14">
        <button
          onClick={handlePrevClick}
          // disabled={currentIndex >= posts.length - 1}
          className="bg-gray-800 text-white px-4 py-2 rounded-md disabled:opacity-60"
        >
          Previous
        </button>
        <button
          onClick={handleNextClick}
          // disabled={currentIndex === 0}
          className="bg-gray-800 text-white px-4 py-2 rounded-md disabled:opacity-60"
        >
          Next
        </button>
      </div>
    </>
  );
}
