import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/login_easier.png";
import axios from "axios";

const ForgotPassword = () => {
  const emailRef = useRef();
  const navigate = useNavigate();
  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/corp-credit-accounts/reset-password-email?email=${emailRef.current.value}`
      )
      .then((response) => {
        setEmailSent(true);
      });
  };

  const returnToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-32 w-auto"
          src={logo}
          alt="Easier Accounting"
        />
      </div>
      <h2
        className="mt-16 text-center text-3xl font-bold tracking-tight text-gray-900"
        style={{
          fontFamily: "Merriweather, serif",
          fontSize: "28px",
        }}
      >
        Forgot password?
      </h2>
      <div className=" sm:mx-auto sm:w-full sm:max-w-md">
        {emailSent ? (
          <div className="bg-white r border py-12 px-4 shadow-[#215369] shadow-md sm:rounded-lg sm:px-10 mt-4">
            <h4
              className="ml-5 mb-8 text-2xl font-bold tracking-wide text-gray-900"
              style={{ fontFamily: "Merriweather, serif" }}
            >
              Success!
            </h4>
            <p className="text-center text-md">
              An email has been sent to the email address provided. Please check
              your inbox and follow the instructions in the email to reset your
              password.
            </p>

            <div className="flex items-center justify-end mt-5">
              <div className="text-sm">
                <button
                  type="button"
                  className="font-medium text-blue-600 hover:text-blue-500"
                  onClick={returnToLogin}
                >
                  Return to login
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="bg-white r border py-12 px-4 shadow-[#215369] shadow-md sm:rounded-lg sm:px-10 mt-4">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <p style={{ marginBottom: "35px" }}>
                Enter the email address associated with your account and we'll
                send you a link to reset your password.
              </p>
              <div>
                <label
                  htmlFor="email"
                  className="block text-[14.5px] font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    ref={emailRef}
                    placeholder="Email"
                    autoComplete="email"
                    required
                    className="block w-full rounded-md  py-1.5 border-solid border-1 border-gray-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="flex items-center justify-end mt-5">
                <div className="text-sm">
                  <button
                    type="button"
                    className="font-medium text-blue-600 hover:text-blue-500"
                    onClick={returnToLogin}
                  >
                    Return to login
                  </button>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-blue-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  Send Email
                </button>
              </div>
            </form>
            <div className="mt-8">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex  justify-center text-sm">
                  <span className="bg-white px-3 py-1 text-gray-500">
                    ©Easier Accounting, All Rights Reserved.
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
