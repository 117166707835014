import './App.css';
import Home from './components/Home/Home';
import Navbar from './components/Navbar/Navbar';
import { Routes, Route } from 'react-router-dom';
import Login from './components/Login/Login';
import Post from './components/Post/Post';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import 'bootstrap/dist/css/bootstrap.min.css';
import PublicRoutes from './components/PublicRoutes/PublicRoutes';
import { useAuth } from './context/AuthContext';
import Contact from './components/Contact/Contact';
import ForgotPassword from './components/Login/ForgotPassword';
import Team from './components/Team/Team';
import ResetPassword from './components/Login/ResetPassword';
import Settings from './components/Settings';


function App() {
	const { currentUser } = useAuth();

	return (
		<div>
			{currentUser && <Navbar />}
			<Routes>
				<Route path='/' element={<PrivateRoute />} >
					<Route path="/" element={<Home />} />
					<Route path='corp-credit/:slug' element={<Post />} />
					<Route path='contact-us' element={<Contact />} />
					<Route path='team' element={<Team />} />
					<Route path='settings' element={<Settings />} />
				</Route>

				<Route path='login' element={<PublicRoutes />}>
					<Route path="/login" element={<Login />} />
					<Route path="/login/forgot" element={<ForgotPassword />} />
					<Route path="/login/reset" element={<ResetPassword />} />
				</Route>
			</Routes>
		</div>
	);
}

export default App;
