import React, { useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import logo from "../../assets/images/login_easier.png";
import axios from "axios";

const ResetPassword = () => {
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const [searchParams] = useSearchParams();
  const [savePassword, setSavePassword] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (passwordRef.current.value !== confirmPasswordRef.current.value) {
      setError("Passwords do not match");
    } else {
      const newPassword = passwordRef.current.value;
      const confirmPassword = confirmPasswordRef.current.value;
      
      const token = searchParams.get("token");
      console.log(`${process.env.REACT_APP_API_URL}/corp-credit/reset-password?token=${token}`);
      axios
        .post(`${process.env.REACT_APP_API_URL}/corp-credit/reset-password?token=${token}`, { password: newPassword, confPassword: confirmPassword })
        .then((response) => {
          console.log("Password reset successful");
          setError("");
          setSavePassword(true)
        })
        .catch((error) => {
          console.log(error)
          setError("Password reset failed.");
        });
    }
  };

  const returnToLogin = () => {
    navigate("/login");
  };

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-32 w-auto"
            src={logo}
            alt="Easier Accounting"
          />

          <h2
            className="mt-16 text-center text-3xl font-bold tracking-tight text-gray-900"
            style={{ fontFamily: "Merriweather, serif", fontSize: "28px" }}
          >
            Create new password
          </h2>
          {savePassword ? (
          <div className="bg-white r border py-12 px-4 shadow-[#215369] shadow-md sm:rounded-lg sm:px-10 mt-4">
            <h4
              className="ml-5 mb-8 text-2xl font-bold tracking-wide text-gray-900"
              style={{ fontFamily: "Merriweather, serif" }}
            >
              Success!
            </h4>
            <p className="text-center text-md">
              Your password has been updated successfully. <br /> Follow the link below to return to the login page.
            </p>

            <div className="flex items-center justify-end mt-5">
              <div className="text-sm">
                <button
                  type="button"
                  className="font-medium text-blue-600 hover:text-blue-500"
                  onClick={returnToLogin}
                >
                  Return to login
                </button>
              </div>
            </div>
          </div>
        ) : (

        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white r border py-12 px-4 shadow-[#215369] shadow-md sm:rounded-lg sm:px-10">
            {error && (
              <div
                className="bg-red-100 mb-3 border border-red-400 text-red-700 px-3 py-2 rounded relative"
                role="alert"
              >
                {error}
              </div>
            )}
            <form className="space-y-6" method="POST" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="password"
                  className="block text-[14.5px] font-medium leading-6 text-gray-900"
                >
                  New Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    ref={passwordRef}
                    placeholder="New Password"
                    required
                    className="block w-full rounded-md  py-1.5 border-solid border-1 border-gray-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                  
                </div>
              </div>

              <div className="mt-2">
                <label
                  htmlFor="confirm-password"
                  className="block text-[14.5px] font-medium leading-6 text-gray-900"
                >
                  Confirm Password
                </label>
                <div className="mt-2 relative">
                  <input
                    id="confirm-password"
                    name="confirm-password"
                    type="password"
                    ref={confirmPasswordRef}
                    placeholder="Confirm Password"
                    required
                    style={{ flex: "1 1 0%", paddingRight: "20px" }}
                    className="block w-full rounded-md border-1 border-gray-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="flex items-center justify-end mt-5">
                <div className="text-sm">
                  <button
                    type="button"
                    className="font-medium text-blue-600 hover:text-blue-500"
                    onClick={returnToLogin}
                  >
                    Return to login
                  </button>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-blue-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  Save Password
                </button>
              </div>
            </form>
            <div className="mt-8">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex  justify-center text-sm">
                  <span className="bg-white px-3 py-1 text-gray-500">
                    ©Easier Accounting, All Rights Reserved.
                  </span>
                </div>
              </div>
            </div>
          </div>
          </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
