import React, { useRef, useState } from "react";
import axios from "axios";
import { AiOutlineKey } from "react-icons/ai";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { useAuth } from "../context/AuthContext";

const Settings = () => {
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const [error, setError] = useState("");
  const [saveNewPassword, setSaveNewPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { token } = useState(localStorage.getItem("token"));
  const { currentUser } = useAuth();

  const handleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(currentUser, "this is user");
    if (passwordRef.current.value !== confirmPasswordRef.current.value) {
      setError("Passwords do not match");
    } else {
      const newPassword = passwordRef.current.value;
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/corp-credit/update-password/${currentUser.id}`,
          { newPassword },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log("Password reset successful");
          setError("");
          setSaveNewPassword(true);
        })
        .catch((error) => {
          setError("Password reset failed.");
        });
    }
  };

  return (
    <div className="flex min-h-full flex-col justify-center py-10 sm:px-6 lg:px-12">
      <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="sm:mx-auto mb-12 sm:w-full sm:max-w-md">
          <h4
            className="mt-16 text-center text-xl font-semibold tracking-tight text-gray-900"
            style={{ fontFamily: "Merriweather, serif", fontSize: "26px" }}
          >
            Update password
            <span className="ml-8 inline-block">
              <AiOutlineKey size={30} />
            </span>
          </h4>
        </div>
        <div className="bg-white border-[1px] shadow-[#215369] shadow-md border-gray-400 py-12 px-4 sm:rounded-lg sm:px-10">
          {saveNewPassword ? (
            <div>
              <h4
                className="ml-5 mb-8 text-2xl font-bold tracking-wide text-gray-900"
                style={{ fontFamily: "Merriweather, serif" }}
              >
                Success!
              </h4>
              <p className="text-center text-md">
                Your password has been updated successfully.
              </p>
            </div>
          ) : (
            <>
              {error && (
                <div
                  className="bg-red-100 mb-3 border border-red-400 text-red-700 px-3 py-2 rounded relative"
                  role="alert"
                >
                  {error}
                </div>
              )}
              <form
                className="space-y-6 py-2 px-2"
                method="POST"
                onSubmit={handleSubmit}
              >
                <div>
                  <label
                    htmlFor="password"
                    className="block text-[14.5px] font-medium leading-6 text-gray-900"
                  >
                    New Password
                  </label>
                  <div className="mt-2 relative">
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      ref={passwordRef}
                      placeholder="New Password"
                      required
                      className="block w-full rounded-md mb-4 py-1.5 border-solid border-1 border-gray-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="mt-3">
                  <label
                    htmlFor="confirm-password"
                    className="block text-[14.5px] font-medium leading-6 text-gray-900"
                  >
                    Confirm Password
                  </label>
                  <div className="mt-2 relative">
                    <input
                      id="confirm-password"
                      name="confirm-password"
                      type={showPassword ? "text" : "password"}
                      ref={confirmPasswordRef}
                      placeholder="Confirm Password"
                      required
                      style={{ flex: "1 1 0%", paddingRight: "20px" }}
                      className="block w-full rounded-md border-1 border-gray-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                    <div
                      type="div"
                      onClick={handleShowPassword}
                      className="focus:outline-none focus:shadow-outline-blue inset-y-0 absolute right-0 pr-3 flex items-center"
                    >
                      {showPassword ? (
                        <HiOutlineEye
                          role="button"
                          className="h-5 w-5 text-gray-700"
                        />
                      ) : (
                        <HiOutlineEyeOff
                          role="button"
                          className="h-5 w-5 text-gray-700"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-blue-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    Save Password
                  </button>
                </div>
              </form>
              <div className="mt-8">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex  justify-center text-sm">
                    <span className="bg-white px-3 py-1 text-gray-500">
                      ©Easier Accounting, All Rights Reserved.
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Settings;
