import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/login_easier.png";
import { useAuth } from "../../context/AuthContext";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import ForgotPassword from "./ForgotPassword";

import "../../assets/styles/login.css";

export default function Login() {
  const auth = useAuth();
  const emailRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();
  const { loginError } = useAuth();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const handleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleForgotPassword = () => {
    setForgotPassword(true);
    navigate("/login/forgot");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isAuthenticated = auth.login(emailRef.current.value, passwordRef.current.value, rememberMe)
    if (!isAuthenticated) {
      if (loginError === 'no user found') {
        setError('Incorrect email or password')
      } else {
        setError(loginError)
      }
    }

  };

  function handleRememberMe(e) {
    setRememberMe(e.target.checked);
  }

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-32 w-auto"
            src={logo}
            alt="Easier Accounting"
          />
          {forgotPassword ? (
            <></>
          ) : (
            <h2
              className="mt-16 text-center text-3xl font-bold tracking-tight text-gray-900"
              style={{ fontFamily: "Merriweather, serif", fontSize: "28px" }}
            >
              Sign in to your account
            </h2>
          )}
        </div>

        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
          {forgotPassword ? (
            <ForgotPassword />
          ) : (
            <div className="bg-white r border py-12 px-4 shadow-[#215369] shadow-md sm:rounded-lg sm:px-10">
              {error && (
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-3 mb-3 -mt-4 py-2 rounded relative text-capitalize"
                  role="alert"
                >
                  {error}
                </div>
              )}
              <form className="space-y-6" onSubmit={handleSubmit} method="POST">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-[14.5px] font-medium leading-6 text-gray-900"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      ref={emailRef}
                      placeholder="Email"
                      autoComplete="email"
                      required
                      className="block w-full rounded-md  py-1.5 border-solid border-1 border-gray-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="mt-2">
                  <label
                    htmlFor="password"
                    className="block text-[14.5px] font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div className="mt-2 relative">
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      ref={passwordRef}
                      placeholder="Password"
                      autoComplete="current-password"
                      required
                      style={{ flex: "1 1 0%", paddingRight: "20px" }}
                      className="block w-full rounded-md border-1 border-gray-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />

                    <div
                      type="div"
                      onClick={handleShowPassword}
                      className="focus:outline-none focus:shadow-outline-blue inset-y-0 absolute right-0 pr-3 flex items-center"
                    >
                      {showPassword ? (
                        <HiOutlineEye
                          role="button"
                          className="h-5 w-5 text-gray-700"
                        />
                      ) : (
                        <HiOutlineEyeOff
                          role="button"
                          className="h-5 w-5 text-gray-700"
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-between mt-5">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 rounded-sm text-blue-600 border-gray-400"
                      checked={rememberMe}
                      onChange={handleRememberMe}
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-2 block text-[14px] text-gray-900"
                    >
                      Remember me
                    </label>
                  </div>

                  <div className="text-sm">
                    <button
                      type="button"
                      onClick={handleForgotPassword}
                      className="font-medium text-blue-600 hover:text-blue-500"
                    >
                      Forgot your password?
                    </button>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-blue-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    Sign in
                  </button>
                </div>
              </form>
              <div className="mt-8">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex  justify-center text-sm">
                    <span className="bg-white px-3 py-1 text-gray-500">
                      ©Easier Accounting, All Rights Reserved.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
